<template>
<div>
  <b-alert variant="danger" :show="errorMsg != null" @click="errorMsg = null" dismissible>
    {{ errorMsg }}
  </b-alert>
  <div v-if="$debug.isOn" class="alert-info mt-2">
    <debug-obj label="dbgConfig" :obj-data="dbgConfig"/>
    MediaRecorder.mimeType: {{ mediaRecorder ? mediaRecorder.mimeType : 'null' }}<br/>
    HLS Player: 
    <shareable :content="previewUrlGCS"/>
    <shareable v-if="wsIp" :content="previewUrl"/>
  </div>
</div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog("stream-recorder");
import { getURLRoot, getHTTPSfromWS } from "@/services/utils";
import { getStreamInfo } from '@/services/mediautils';
import Shareable from './shareable.vue';
import { storage } from "@/services/db";
import { stopStream } from '@/services/mediautils';

export default {
  components: {
    Shareable
  },
  props: {
    stream: null,
    config: {
      type: Object,
      default: () => { return {}; },
    }
  },
  data() {
    return {
      recording: false,
      disabled: false,
      ws: null,
      errorMsg: null,
      mediaStream: null,
      mediaRecorder: null,
      mediaRecorderInterval: 1000,

      canvasId: null,
      wsIp: null,
      mimeType: null,
      hls: false,
      hlsBucket: null,
      rtmpUrl: null,
      rtmpNoVideoConversion: false,
      rtmpNoAudioConversion: false,
      mimeTypeList: [
        '(autodetect)',
        'video/webm;codecs=h264',
        'video/mp4',
      ],
      mimeTypeListExperimental: [
        'video/webm;codecs=vp9',
      ],
      msbVersion: 1,
      dbgConfig: null,
    }
  },
  computed: {
    canStart() { return !this.recording && !this.disabled; },
    canStop() { return this.recording && !this.disabled; },    
    previewUrlGCS() {
      return `${getURLRoot()}/tests/test-hls?url=` + encodeURIComponent(`https://storage.googleapis.com/hh-streams/${this.hlsBucket}/vod.m3u8`);
    },
    previewUrl() {
      return `${getURLRoot()}/tests/test-hls?url=` + encodeURIComponent(`${getHTTPSfromWS(this.wsIp)}/${this.hlsBucket}/vod.m3u8`);
    }
  },
  mounted() {
    log.log("mounted");
  },
  beforeDestroy() {
    log.log("beforeDestroy");
    if (this.mediaRecorder)
      this.stop(true);
    if (this.ws)
      this.ws.close(3009);
  },
  methods: {
    setConfig(config) {
      if (config)
        config = Object.assign(Object.assign({}, this.config), config);
      else 
        config = this.config;
      this.dbgConfig = config;
      log.log("setConfig", config);
      this.wsIp = config.wsIp;
      this.mimeType = config.mimeType || this.mimeTypeList[0];
      this.rtmpUrl = config.rtmpUrl;
      this.hlsBucket = config.hlsBucket;
      this.mediaRecorderInterval = config.mediaRecorderInterval || this.mediaRecorderInterval;
      this.canvasId = config.canvasId;
      if (this.hlsBucket)
        this.hls = true;
      this.getMimeType();
    },
    getPreviewUrl(folder) {
      return `${getHTTPSfromWS(this.wsIp)}/${folder}/vod.m3u8`;
    },
    getMimeType() {
      if (this.mimeType == '(autodetect)') {
        for (let i in this.mimeTypeList) {   
          if (MediaRecorder.isTypeSupported(this.mimeTypeList[i])) {
            this.mimeType = this.mimeTypeList[i];
            break;
          }
        }
        log.log(`Autodetected ${this.mimeType}`);
      }
      if (this.mimeType == '(autodetect)') {
        this.errorMsg = "This browser does not support required codec, please try using a supported device";
        this.disabled = true;
        return null;
      }
      return this.mimeType;
    },
    startUsingWebSocket() {
      this.errorMsg = null;
      try {
        log.log("startLiveStream (WS)", this.stream, this.wsIp, this.hlsBucket, getStreamInfo(this.stream));
        let wsUrl = this.wsIp + '/rtmp/?';
        wsUrl += "mimetype=" + encodeURIComponent(this.getMimeType());
        if (this.rtmpUrl)
          wsUrl += '&url=' + encodeURIComponent(this.rtmpUrl);
        if (this.hls)
          wsUrl += '&hls=' + this.hlsBucket;
        if (this.canvasId)
          wsUrl += "&noaudio=1";
        if (this.rtmpNoVideoConversion)
          wsUrl += "&novideoconversion=1";
        if (this.rtmpNoAudioConversion)
          wsUrl += "&noaudioconversion=1";
        log.log("wsUrl", wsUrl);

        let ws = new WebSocket(wsUrl);
        this.ws = ws;
        ws.onerror = (e) => {
          log.error("error=", e);
          this.errorMsg = "Server Error";
        }

        ws.addEventListener('open', (e) => {
          try {
            log.log('WebSocket Open', e);
            this.mediaRecorder = new MediaRecorder(this.mediaStream, {
              mimeType: this.mimeType,
              videoBitsPerSecond : 3000000,
              sampleRate: 44100,
            });

            this.mediaRecorder.ondataavailable = (e) => {
              log.log("mediaRecorder dataavailable")
              if (ws.readyState === WebSocket.OPEN)
                ws.send(e.data);
              else {
                log.log("socket closed stopping recording");
                this.stop();
              }
            };

            this.mediaRecorder.onstop = () => {
              log.log("mediaRecorder onstop")
              ws.close(1000);
            };

            this.mediaRecorder.start(this.mediaRecorderInterval); // Start recording, and dump data every second

            this.updateRecording(true);
          } 
          catch (e) {
            this.errorMsg = e;
          }
        });

        ws.addEventListener('close', (e) => {
          log.log('WebSocket Close', e);
          this.updateRecording(false);
          // if Web Socket closes without user pressing stop
          if (this.mediaRecorder && this.mediaRecorder.state == MediaRecorder.recording) {
            log.log("stopping media recorder");
            this.mediaRecorder.stop();
          }
        });
      }
      catch (e) {
        this.errorMsg = e;
      }
    },
    startUsingRemoteStorage() {
        log.log("startLiveStream (GS)", this.stream, this.wsIp, this.hlsBucket, getStreamInfo(this.stream));

        let mimeType = this.mimeType;
        this.mediaRecorder = new MediaRecorder(this.mediaStream, {
          mimeType,
          videoBitsPerSecond : 3000000,
          sampleRate: 44100,
        });
        this.count = 0;
        this.manifest = null;
        log.log("mediaRecorder", this.mediaRecorder.mimeType);
        let remoteFolder = this.hlsBucket;
        let extension = "msb";

        this.mediaRecorder.ondataavailable = (e) => {
          log.log("mediaRecorder dataavailable", e);
          this.uploadFile(`${this.wsIp}/${remoteFolder}/stream${this.count.toString().padStart(4, '0')}.${extension}`, e.data);
          if (!this.count)
            this.uploadDesc(remoteFolder, mimeType);
          this.count += 1;
        };

        this.mediaRecorder.start(this.mediaRecorderInterval);
        this.updateRecording(true);
    },
    async uploadFile(path, blob) {
      log.log("uploadFile", path, blob);
      let res = await storage.refFromURL(path).put(blob);
      log.log("uploadFile result=", res);
    },
    uploadDesc(remoteFolder, mimeType) {
      let desc = JSON.stringify({
        mimeType,
        interval: this.mediaRecorderInterval,
        version: this.msbVersion,
      });
      this.uploadFile(`${this.wsIp}/${remoteFolder}/stream.json`, new Blob([desc]));
    },
    // Start recording
    start(config, stream) {
      try {
        this.setConfig(config);
        this.mediaStream = stream || (this.canvasId ? document.getElementById(this.canvasId).captureStream(30) : this.stream);
        log.log('mediaStream=', this.mediaStream);        
        if (this.wsIp.startsWith("gs://"))
          this.startUsingRemoteStorage();
        else
          this.startUsingWebSocket();
      }
      catch (e) {
        log.error(e);
        this.errorMsg = e;
      }        
    },
    // Stop recording
    stop(closing) {
      log.log("stop");
      if (this.recording) {
        this.mediaRecorder.stop();
        this.updateRecording(false, closing);
        if (this.canvasId)
          stopStream(this.mediaStream);
      }
    },
    // Emit recording change event
    updateRecording(value, closing) {
      log.log("updateRecording", value);
      this.recording = value;
      this.$emit('recording-change', this.recording, closing);
    }
  }
}
</script>

<style>

</style>