<template>
<div>
  <!-- Editor -->
  <stream-recorder-editor ref="editor" class="m-2"
    :config="{mediaRecorderInterval:1000}"/>
  <!-- Recorder -->
  <stream-recorder ref="recorder" class="m-2"
    :config="{canvasId:'canvas', hlsBucket:'test-streaming'}"/>
  <!-- Buttons -->
  <div class="form-inline sub-mr-2 sub-mt-2 ml-2 mb-2" v-if="$refs.recorder">
    <b-button class="btn btn-primary" @click="$refs.recorder.start($refs.editor.recorderConfig)" 
      :disabled="!$refs.recorder.canStart">Start</b-button>
    <b-button class="btn btn-danger" @click="$refs.recorder.stop()" :disabled="!$refs.recorder.canStop">Stop</b-button>
  </div>
  <!-- Canvas Animation -->
  <canvas id="canvas" width="854" height="480" style="background-color: black" class="render"></canvas>
</div>
</template>

<script>
import { getLog } from "@/services/log";
import StreamRecorder from "@/components/streamRecorder.vue";
import StreamRecorderEditor from '@/components/streamRecorderEditor.vue';
let log = getLog("test-streaming");

export default {
  components: { 
    StreamRecorder,
    StreamRecorderEditor 
  },
  data() {
    return {
      sun: new Image(),
      moon: new Image(),
      earth: new Image(),
    }
  },
  mounted() {
    this.$debug.isOn = true;
    this.$nextTick(() => {
      this.init();
    });
  },
  methods: {
    init() {
      log.log("init");
      this.sun.src = require("@/assets/Canvas_sun.png");
      this.moon.src = require("@/assets/Canvas_moon.png");
      this.earth.src = require("@/assets/Canvas_earth.png");
      window.requestAnimationFrame(this.draw.bind(this));
    },
    draw() {
      var ctx = document.getElementById('canvas').getContext('2d');
      let c = {x: 854/2, y: 480/2};

      ctx.globalCompositeOperation = 'destination-over';
      ctx.clearRect(0, 0, 854, 480); // clear canvas

      ctx.fillStyle = 'rgba(0, 0, 0, 0.4)';
      ctx.strokeStyle = 'rgba(0, 153, 255, 0.4)';
      ctx.save();
      ctx.translate(c.x, c.y);

      // Earth
      var time = new Date();
      ctx.rotate(((2 * Math.PI) / 60) * time.getSeconds() + ((2 * Math.PI) / 60000) * time.getMilliseconds());
      ctx.translate(105, 0);
      ctx.fillRect(0, -12, 40, 24); // Shadow
      ctx.drawImage(this.earth, -12, -12);

      // Moon
      ctx.save();
      ctx.rotate(((2 * Math.PI) / 6) * time.getSeconds() + ((2 * Math.PI) / 6000) * time.getMilliseconds());
      ctx.translate(0, 28.5);
      ctx.drawImage(this.moon, -3.5, -3.5);
      ctx.restore();

      ctx.restore();

      ctx.beginPath();
      ctx.arc(c.x, c.y, 105, 0, Math.PI * 2, false); // Earth orbit
      ctx.stroke();

      ctx.drawImage(this.sun, c.x - 150, c.y - 150, 300, 300);

      window.requestAnimationFrame(this.draw.bind(this));
    },
  }
}
</script>

<style>

</style>