<template>
<div>
  <div class="form-inline sub-mr-2 sub-mt-2">
    <label>WS Server:</label>
    <b-form-select size="sm" v-model="recorderConfig.wsIp" :options="wsList"></b-form-select>
    <label>MimeType:</label>
    <b-form-select size="sm" v-model="recorderConfig.mimeType" :options="mimeTypeList"></b-form-select>
    <label>Interval:</label>
    <b-input size="sm" v-model="recorderConfig.mediaRecorderInterval"/>
    <b-form-checkbox v-model="recorderConfig.hls">Generate HLS</b-form-checkbox>
  </div>
  <div class="form-inline sub-mr-2 sub-mt-2">      
    <label class="mr-sm-2">Bucket Name:</label>
    <b-input class="mb-2 mr-sm-2 mb-sm-0 w-50" size="sm" v-model="recorderConfig.hlsBucket"></b-input>
  </div>
  <div class="form-inline sub-mr-2 sub-mt-2">
    <label class="mr-sm-2">RTMP Server:</label>
    <b-input class="mb-2 mr-sm-2 mb-sm-0 w-50" size="sm" v-model="recorderConfig.rtmpUrl" disabled></b-input>
    <b-form-select size="sm" v-model="recorderConfig.rtmpUrl" :options="rtmpList"></b-form-select>
  </div>
  <div class="form-inline sub-mr-2 sub-mt-2">
    <label>Options:</label>
    <b-form-checkbox v-model="recorderConfig.rtmpNoVideoConversion">Force No Video Conversion</b-form-checkbox>
    <b-form-checkbox v-model="recorderConfig.rtmpNoAudioConversion">Force No Audio Conversion</b-form-checkbox>
  </div>
</div>
</template>

<script>
import { productionStreamingServerIp } from "@/services/appconfig";

export default {
  props: {
    config: {
      type: Object,
      default: () => { return {}; },
    }
  },
  data() {
    return {
      recorderConfig: {},

      wsList: [
        "gs://hh-streams/recordings", // gstorage upload
        productionStreamingServerIp, // vm-instance
        "wss://streaming.happyhour.live",
        "wss://streaming-xl.happyhour.live",
        "wss://streamozoa.ngrok.io",
        "ws://localhost:3000",
        "wss://localhost:443",
        "ws://localhost:80",
        "wss://streaming.default.34.94.56.233.xip.io", // cluster
      ],
      mimeTypeList: [
        '(autodetect)',
        'video/webm;codecs=h264',
        'video/mp4',
      ],
      mimeTypeListExperimental: [
        'video/webm;codecs=vp9',
      ],
      rtmpList: [
        { text: "Disabled", value: null},
        { text: "YouTube Live", value: "rtmp://a.rtmp.youtube.com/live2/sh38-y1j3-vya6-34kh-7jra"},
        { text: "Facebook Live", value: "rtmps://live-api-s.facebook.com:443/rtmp/10158941749512836?s_bl=1&s_ps=1&s_psm=1&s_sw=0&s_vt=api-s&a=AbwZIKoUt--SSI6H"},
        { text: "AWS EML 1", value: "rtmp://35.81.115.239:1935/live/streaming-0"},
        { text: "AWS EML 2", value: "rtmp://34.212.203.186:1935/live/streaming-1"},
        { text: "Restream", value: "rtmp://live.restream.io/live/re_4535681_f14868f6e363a4ffee2b"}
      ]
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.recorderConfig = this.config;
      this.recorderConfig.wsIp = this.recorderConfig.wsIp || this.wsList[0];
      this.recorderConfig.mimeType = this.recorderConfig.mimeType || this.mimeTypeList[0];
      this.recorderConfig.rtmpUrl = this.recorderConfig.rtmpUrl || this.rtmpList[0].value;
    }
  }
}
</script>

<style>

</style>